<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="(id) => getAllCinemaList(id, false)">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="规则名称" name="refundName">
						<a-input v-model:value="formState.refundName" placeholder="请输入规则名称"></a-input>
					</a-form-item>
					
<!--					<a-form-item class="ui-form__item" label="支付类型" name="ticketPayment">-->
<!--						<a-select v-model:value="formState.ticketPayment" style="width: 190px;" placeholder="请选择支付类型">-->
<!--							<a-select-option :value="0">全部</a-select-option>-->
<!--							<a-select-option :value="1">影城兑换券</a-select-option>-->
							<!-- <a-select-option :value="2">影城会员卡</a-select-option> -->
<!--							<a-select-option :value="3">次数卡</a-select-option>-->
							<!-- <a-select-option :value="4">优惠券</a-select-option> -->
<!--							<a-select-option :value="5">年卡</a-select-option>-->
<!--							<a-select-option :value="6">微信</a-select-option>-->
							<!-- <a-select-option :value="7">支付宝</a-select-option> -->
<!--						</a-select>-->
<!--					</a-form-item>-->
					
					<a-form-item class="ui-form__item" label="是否启用" name="isDisabled">
						<a-select v-model:value="formState.isDisabled" style="width: 190px;" placeholder="请选择是否启用">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
							<a-select-option :value="0">启用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['ciname_policy_refund_add']" type="primary" @click="onAdd">添加</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" rowKey="id" :columns="columns" :dataSource="list" :scroll="{ x: 1300 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'ticketPayment'">
							默认
<!--							{{ ['影城兑换券', '影城会员卡', '次数卡', '优惠券', '年卡', '微信', '支付宝'][record.ticketPayment - 1] }}-->
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onEdit(record, true)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['ciname_policy_refund_edit']" @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
										<div v-permission="['ciname_policy_refund_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
		</a-spin>
		
		<a-modal v-model:visible="showModal" title="添加" width="750px">
			<template #footer>
				<a-button @click="showModal = false">取消</a-button>
				<a-button v-if="!isSee" type="primary" @click="onSubmit(true)">提交</a-button>
				<!-- <a-button v-if="!modelRef.id" type="primary" @click="onSubmit(false)">添加并继续</a-button> -->
			</template>
			<a-spin :spinning="loading">
				<a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm">
					
					<a-form-item label="影院组织" name="organizationId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select :disabled="isSee" v-model:value="modelRef.organizationId" placeholder="请选择影院组织" @change="(id) => getAllCinemaList(id, true)">
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="所属影院" name="cinemaId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select :disabled="isSee" v-model:value="modelRef.cinemaId" placeholder="请选择所属影院">
							<a-select-option v-for="item in cinemaListFormAdd" :value="item.id" :key="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="规则名称" name="refundName" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input :disabled="isSee" v-model:value="modelRef.refundName" placeholder="请输入规则名称"></a-input>
					</a-form-item>
					
					<a-row>
						<a-col :span="12">
							<a-form-item label="可退次数" name="everydayRefundCount" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.everydayRefundCount" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<span style="padding-left: 6px;">
									 <a-tooltip>
									    <template #title>单用户每天可退多少次数，设置为0则不限制</template>
									    <Icon icon="QuestionCircleOutlined"></Icon>
									  </a-tooltip>
								</span>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="场次可退时间" name="beforeShowTime" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.beforeShowTime" :precision="0" placeholder="请输入"></a-input-number>
								<span style="padding-left: 6px;">
									分钟
									 <a-tooltip style="margin-left: 6px;">
									    <template #title>开场前多少分钟可退（正数：开场前，负数：开场后）</template>
									    <Icon icon="QuestionCircleOutlined"></Icon>
									  </a-tooltip>
								</span>
							</a-form-item>
						</a-col>
					</a-row>
					
					<a-form-item label="服务费类型" name="serviceType" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-radio-group :disabled="isSee" v-model:value="modelRef.serviceType" button-style="solid">
							<a-radio-button :value="1">按每张票算</a-radio-button>
							<a-radio-button :value="2">按每笔订单算</a-radio-button>
						</a-radio-group>
					</a-form-item>
					
					<a-row>
						<a-col :span="12">
							<a-form-item label="服务费金额" name="serviceFee" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.serviceFee" :min="0" :precision="2" placeholder="请输入"></a-input-number>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="优先级" name="refundLevel" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.refundLevel" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<span style="padding-left: 6px;">
									 <a-tooltip>
									    <template #title>数字越大,优先级越大;如果同级,最新创建的优先级大</template>
									    <Icon icon="QuestionCircleOutlined"></Icon>
									  </a-tooltip>
								</span>
							</a-form-item>
						</a-col>
					</a-row>
					
					<a-form-item label="票支付方式" name="ticketPayment" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select :disabled="isSee" v-model:value="modelRef.ticketPayment" placeholder="请选择票支付方式">
							<!-- <a-select-option :value="1">影城兑换券</a-select-option> -->
							<!-- <a-select-option :value="2">影城会员卡</a-select-option> -->
							<!-- <a-select-option :value="3">次数卡</a-select-option> -->
							<!-- <a-select-option :value="4">优惠券</a-select-option> -->
							<!-- <a-select-option :value="5">年卡</a-select-option> -->
							<a-select-option :value="6">默认（微信、次卡、年卡、兑换券）</a-select-option>
<!--							<a-select-option :value="6">微信</a-select-option>-->
							<!-- <a-select-option :value="7">支付宝</a-select-option> -->
						</a-select>
					</a-form-item>
					
					<a-row>
						<!-- <a-col :span="12">
							<a-form-item name="isMixed" label="混合支付是否可退" :rules="[{required: true, message: '必选项不允许为空'}]">
								<a-switch v-model:checked="modelRef.isMixed" checked-children="可退" un-checked-children="不可退" />
							</a-form-item>
						</a-col> -->
						
						<a-col :span="12">
							<a-form-item name="isDisabled" label="是否启用" :rules="[{required: true, message: '必选项不允许为空'}]">
				 				<a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
						</a-col>
					</a-row>
					
					<a-form-item label="规则详情" name="refundDetail" :rules="[{required: true, message: '必填项不允许为空'}]">
						<div style="display: flex;align-items: flex-end;">
							<div style="width: 400px;">
								<a-textarea :disabled="isSee" v-model:value="modelRef.refundDetail" :rows="6" :maxLength="800"></a-textarea>
							</div>
							<div>
								<span style="padding: 0 10px;">
									<a-tooltip>
									   <template #title>总共限制800个字符，换行需要输入“|”字符</template>
									   <Icon icon="QuestionCircleOutlined"></Icon>
									 </a-tooltip>
								</span>
								<a-button :disabled="isSee" type="primary" @click="onRanderDetail">生成详情</a-button>
							</div>
						</div>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getRefundPolicyList, deleteRefundPolicy, saveRefundPolicy, updateRefundPolicy, getRefundPolicyDetail } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				cinemaListFormAdd: [],
				formState: {
					isDisabled: -1,
					ticketPayment: 0,
					// organizationId: 0,
					cinemaId: 0,
				},
				searchData: {},
				showModal: false,
				modelRef: {
					everydayRefundCount: 1,
					beforeShowTime: 15,
					serviceType: 1,
					serviceFee: 0,
					sort: 0,
					// isMixed: false,
					isDisabled: false
				},
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinema'
				}, {
					title: '规则名称',
					dataIndex: 'refundName'
				}, {
					title: '服务费金额',
					dataIndex: 'serviceFee'
				}, {
					title: '支付类型',
					key: 'ticketPayment'
				}, {
					title: '是否启用',
					key: 'isDisabled'
				}, {
					title: '优先级',
					dataIndex: 'refundLevel'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				isSee: false,
				id: 0
			}
		},
		created() {
			this.getOrganizationList();
			// this.onSearch();
		},
		methods: {
			onBack() {
				this.showModal = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.ticketPayment = this.searchData.ticketPayment ? this.searchData.ticketPayment : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled >= 0 ? this.searchData.isDisabled : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getRefundPolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId, isAdd) {
				if (isAdd) {
					this.cinemaListFormAdd = [];
					this.modelRef.cinemaId = undefined;
				} else {
					this.cinemaAllList = [];
					this.formState.cinemaId = 0;
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					if (isAdd) {
						this.cinemaListFormAdd = ret.data.list;
					} else {
						this.cinemaAllList = ret.data.list;
					}
				}
			},
			onAdd() {
				this.modelRef = {
					everydayRefundCount: 1,
					beforeShowTime: 15,
					serviceType: 1,
					serviceFee: 0,
					sort: 0,
					// isMixed: false,
					isDisabled: false,
					refundDetail: ''
				};
				this.isSee = false;
				this.showModal = true;
			},
			async onEdit(item, isSee) {
				this.loading = true;
				try {
					let ret = await getRefundPolicyDetail({
						id: item.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.getAllCinemaList(ret.data.organizationId, true);
						ret.data.isDisabled = ret.data.isDisabled ? false : true;
						// ret.data.isMixed = ret.data.isMixed ? true : false;
						this.isSee = isSee || false;
						this.modelRef = ret.data;
						this.showModal = true;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onRanderDetail() {
				this.modelRef.refundDetail = `1.影片开场${ this.modelRef.beforeShowTime <= 0 ? '后'+ (this.modelRef.beforeShowTime === 0 ? '' : Math.abs(this.modelRef.beforeShowTime) + '分钟内') +'不可进行退款' : '前' + this.modelRef.beforeShowTime + '分钟可退款' }；|2. 按每${ this.modelRef.serviceType === 1 ? '张票' : '笔订单' }算，将收取${ this.modelRef.serviceFee }元/张影票的服务费；|3. 仅支持线上的电影票进行退款(特殊电影票除外)；|4. 仅限整单退款，不支持部分座位退款；|5. ${ this.modelRef.beforeShowTime >= 0 ? '如购买场次已放映或您已取票，' : '如您已取票或' }非影院原因造成不能正常观影，不支持退款；|6. 如发现舞弊行为，线上购票平台有权取消您的退票资格；|7. 退款规则的最终解释权归影院所有；`;
			},
			onSubmit(isRef) {
				this.$refs.addForm.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.isDisabled = postData.isDisabled ? 0 : 1;
					// postData.isMixed = postData.isMixed ? 1 : 0;
					try {
						let ret;
						this.loading = true;
						if (postData.id) {
							ret = await updateRefundPolicy(postData);
						} else {
							ret = await saveRefundPolicy(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							if (isRef) {
								this.showModal = false;
								this.getData();
							} else {
								this.modelRef = {};
								this.$refs.addForm.resetFields();
							}
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteRefundPolicy({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
